import React from "react";
import Header from "../../../../containers/Header";

const BeforeExchange = () => {
  return (
    <div>
      <Header />
      <main className="main">
        <iframe
          // src="https://design.powerplay247.com/auth"
          src="https://sportsapp.casinospm.app/launch?theme=slate-theme&token=9db64345-0f77-4342-9454-f7f85786d9ea/auth"
          style={{ width: "100%", height: "calc(100vh - 130px)" }}
        />
      </main>
    </div>
  );
};

export default BeforeExchange;
